import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import logo from '../../assets/clearbiz2.png';
import clearbiz3 from "../../assets/clearbiz3.png"
import qrCodeImage from '../../assets/Upi.png';
import qr1 from '../../assets/qr1.png';
import PaymentSuccess from '../Paymentsuccess';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';



import { postPayment } from '../../store/actions';

const theme = createTheme();

export default function PaymentPage() {
  const paymentId = localStorage.getItem('paymentId');
  const offer_Prize = localStorage.getItem("offer_Prize")
  const subscription_type = localStorage.getItem("subscription_type")
  const navigate = useNavigate();
  const { successData: postPaymentData, error: paymentError, errorInfo: paymentErrorInfo } = useSelector(state => state.postPaymentDetails) || {};

  const [transactionId, setTransactionId] = React.useState('');
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);


  React.useEffect(() => {
    if (postPaymentData) {
      localStorage.setItem("is_subscription_active", true)
      setOpen(true);
    }
  }, [postPaymentData]);

  React.useEffect(() => {
    if (paymentError) {
      toast.error(paymentErrorInfo)
    }
  }, [paymentError, paymentErrorInfo]);

  const onSubmit = () => {
    const data = {
      transaction_id: transactionId,
      payment_amount: offer_Prize,
      subscription: parseInt(paymentId),
    };
    dispatch(postPayment(data));
    localStorage.removeItem('offer_prize');

  };
  const handleClose = () => {
    setOpen(false);
    navigate('/');
    window.location.href = '/';

  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppBar position="static" sx={{ backgroundColor: '#fff' }}>
        <Toolbar>
        <img src={clearbiz3} style={{ maxWidth:'200px', height: 'auto',marginBottom:'0%' }} alt="ClearBiz Logo" />
        </Toolbar>
      </AppBar>
      <Container maxWidth="sm" style={{ marginTop: '1rem', textAlign: 'center', marginBottom: '5%' }}>
        <Grid container item xs={12} sm={12} md={12} lg={12} sx={{ ml: { xs: '0%', sm: '0%', md: '0%' } }}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <div style={{ marginTop: '20px' }}>
              <img src={qr1} alt="QR Code" style={{ maxWidth: '100%',marginLeft:'-25%' }} />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <div style={{ marginBottom: '20px'}}>
              <Card sx={{ ml: { xs: '0%', sm: '0%', md: '15%' }, minWidth: 275, marginBottom: 2, marginTop: 6, borderRadius: '10px', background: '#fff' }}>
                <CardContent>
                  <Typography variant="h5" component="div" gutterBottom style={{
                    fontWeight: 'bold',
                    background: 'linear-gradient(90deg, #1E3A8A 0%, #1D4ED8 50%)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                    backgroundClip: 'text',
                    textFillColor: 'transparent',
                    fontFamily: '"Ruwudu", "Times New Roman", serif'

                  }}>
                    Plan Details
                  </Typography>
                  <Typography color="text.secondary" sx={{
                    background: 'linear-gradient(90deg, #3B82F6 0%, #1D4ED8 50%, #1E3A8A 100%)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                    backgroundClip: 'text',
                    textFillColor: 'transparent',
                    fontFamily: '"Ruwudu", "Times New Roman", serif'

                  }}>
                    <strong>Subscription Type:</strong> {subscription_type}
                  </Typography>
                  <Typography variant="body1" sx={{
                    background: 'linear-gradient(90deg, #3B82F6 0%, #1D4ED8 50%, #1E3A8A 100%)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                    backgroundClip: 'text',
                    textFillColor: 'transparent',
                    fontFamily: '"Ruwudu", "Times New Roman", serif'

                  }}>
                    <strong>Price:</strong> ₹{offer_Prize}
                  </Typography>
                  <Typography variant="body1" sx={{
                    background: 'linear-gradient(90deg, #3B82F6 0%, #1D4ED8 50%, #1E3A8A 100%)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                    backgroundClip: 'text',
                    textFillColor: 'transparent',
                    fontFamily: '"Ruwudu", "Times New Roman", serif'

                  }}>
                    <strong>Validity:</strong> {"1 Year"}
                  </Typography>
                </CardContent>

              </Card>
              <div style={{ marginTop: { xs: '5%', sm: '5%', md: '10%' } }}>
                <TextField
                  label="Transaction Id"
                  variant="outlined"
                  fullWidth
                  sx={{ width: { sm: '100%', xs: '100%', md: '150%' }, ml: { xs: '0%', sm: '0%', md: '15%' } }}
                  inputProps={{ maxLength: 12 }}
                  onChange={(e) => setTransactionId(e.target.value)}
                />
                <Button
                  variant="contained"
                  sx={{
                    background: 'linear-gradient(90deg, #1E3A8A 0%, #1D4ED8 50%, #3B82F6 100%)', // Deeper blue to a lighter blue/turquoise
                    color: '#FFFFFF',
                    width: '100%',
                    marginTop: { xs: '5%', sm: '5%', md: '10%' },
                    marginLeft: { sm: '0%', md: '40%', xs: '0%' },
                    borderRadius: '20px',
                    fontFamily: '"Ruwudu", "Times New Roman", serif',
                    '&:hover': {
                      background: 'linear-gradient(90deg, #3B82F6 0%, #1D4ED8 50%, #1E3A8A 100%)', // Reverse gradient on hover
                    },
                  }}
                  disabled={transactionId.length < 12}
                  onClick={onSubmit}
                >
                  Submit
                </Button>


              </div>
            </div>
          </Grid>
        </Grid>
        <Dialog open={open} onClose={handleClose}>
          <PaymentSuccess handleClose={handleClose} />
        </Dialog>
      </Container>
      {/* Payment Success Popup */}

    </ThemeProvider>
  );
}
