import React, { useState } from "react";
import "../../Partnerdetails/Partnerdetails.scss"
import SideBar from "../../../components/sidebar";
import Ratingcard from "../../../components/ratingcard";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { adminUserRatingDetailsAction } from '../../../store/actions';
import { getOverallDetailsOfUser } from "../../../utils";
import PeopleIcon from "@mui/icons-material/People";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import {
  Typography,
  Box,
  Grid,
  useMediaQuery,
  Avatar,
} from "@mui/material";

import Comments from "../../../components/comments";
import OutstandingLoans from "../../../components/outstandings";
import PendingReceivables from "../../../components/pendingReceivables";
import LegalProceedings from "../../../components/legalProceedings";
import Reachability from "../../../components/reachability";
import RecoveryProbability from "../../../components/recoveryProbability";
import IsGSTPaid from "../../../components/isgstpaid";
import CapitalWorth from "../../../components/capitalWorth";
import AssetWorth from "../../../components/assetWoth";

const UserRatingDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const isMobile = useMediaQuery("(max-width: 767px)");

  const { successData: ratingData } = useSelector((state) => state.getAdminUserRatingDetailsReducer) || {};
  const [userID] = useState(location.state.selectedAssocPartner);
  const [partnersRatings, setPartnersRatings] = useState([]);
  const [overallDetails, setOverallDetails] = useState({
    overAllRatings: {
      1: 0.0, 2: 0.0, 3: 0.0, 4: 0.0, 5: 0.0,
      6: 0.0, 7: 0.0, 8: 0.0, 9: 0.0, 10: 0.0,
    },
    totalAmountBalance: 0,
    totalCustomers: 0,
    totalPartners: 0,
    avgRating: 0,
  });

  const [outStanding, setOutStanding] = useState("");
  const [pendingReceivables, setPendingReceivables] = useState("");
  const [totalCustomer, setTotalCustomer] = useState(0);
  const [dsoAverageDays, setDsoAverageDays] = useState(0);
  const [legalProceedings, setLegalProceedings] = useState(0);
  const [reachability, setReachability] = useState(0);
  const [reachabilityCustomerCount, setReachabilityCustomerCount] = useState(0);
  const [comments, setComments] = useState([{
    heading: "",
    comment: "",
  }]);
  const [recoveryProbability, setRecoveryProbability] = useState([{
    yes: 0,
    yes_with_multiple_attempts: 0,
    no: 0,
    difficult: 0,
  }]);
  const [assetWorth, setAssetWorth] = useState([{
    Yes: 0,
    Moderate: 0,
    No: 0,
  }]);
  const [capitalWorth, setCapitalWorth] = useState([{
    Y: 0,
    M: 0,
    N: 0,
  }]);
  const [gstPaid, setGstPaid] = useState([{
    yes: 0,
    no: 0,
  }]);

  React.useEffect(() => {
    if(userID){
      dispatch(
        adminUserRatingDetailsAction({
          endPoint: `admin/admin-partner-ratings?user_id=${userID}`,
        })
      );
    }
  }, [dispatch, userID]);

  React.useEffect(() => {
    if (ratingData) {
      let successData = ratingData.data;
      const parameterToValue = {
        0: 0, 1: 100000, 2: 350000, 3: 750000,
        4: 1500000, 5: 3500000, 6: 7500000, 7: 10000000,
      };

      const dosParameter = {
        0: 0, 1: 30, 2: 60, 3: 90,
        4: 180, 5: 365, 6: 730,
      };

      let reachabilityCustomerCount = 0;
      let noOfLegalProceeding = 0;
      let reachabilityPercentage = 0;

      const yesCount = successData.reduce((count, data) => {
        const parameter = data["reachability"];
        if (parameter !== null && parameter === 0) {
          return count + 1;
        }
        return count;
      }, 0);

      const difficultCount = successData.reduce((count, data) => {
        const parameter = data["reachability"];
        if (parameter !== null && parameter === 0) {
          return count + 1;
        }
        return count;
      }, 0);

      successData.forEach((data) => {
        if (data["reachability"] !== null) {
          reachabilityCustomerCount++;
        }
      });

      if (yesCount) {
        reachabilityPercentage = (yesCount / reachabilityCustomerCount) * 100;
      } else if (difficultCount) {
        reachabilityPercentage = (difficultCount / reachabilityCustomerCount) * 50;
      }

      const totalDso = successData.reduce((sum, data) => {
        const parameter = data["dso"];
        return sum + dosParameter[parameter];
      }, 0);

      const dsoAverageDays = totalDso / successData.length;

      const totalSumOustanding = successData.reduce((sum, data) => {
        const parameter = data["total_outstanding"];
        if (parameter === 0) return sum;
        return sum + parameterToValue[parameter];
      }, 0);

      const totalCustomer = successData.length;

      const totalSumRecievable = successData.reduce((sum, data) => {
        const parameter = data["pending_receivables"];
        if (parameter === 0) return sum;
        return sum + parameterToValue[parameter];
      }, 0);

      successData.map((data) => {
        const userType = data["business_partner_assoc"]["user_type"]["name"];
        const industryType = data["business_partner_assoc"]["business"]["industry"]["name"];
        const location = data["business_partner_assoc"]["business"]["city"] + "," + data["business_partner_assoc"]["business"]["state"];
        const heading = `${industryType} ${userType} from ${location} `;
        const comment = data["comments"];

        if (comment && heading) {
          setComments((prevComment) => [...prevComment, { heading, comment }]);
        }
        return "";
      });

      successData.forEach((data) => {
        if (data["legal_proceedings"]) {
          noOfLegalProceeding++;
        }
      });

      successData.forEach((data) => {
        const parameter = data["recovery_probability"];
        if (parameter !== null) {
          setRecoveryProbability((prevRecovery) => {
            const newRecovery = [...prevRecovery];
            const lastItem = newRecovery[newRecovery.length - 1];
            if (parameter === 0) lastItem.yes += 1;
            else if (parameter === 1) lastItem.yes_with_multiple_attempts += 1;
            else if (parameter === 2) lastItem.difficult += 1;
            else if (parameter === 3) lastItem.no += 1;
            return newRecovery;
          });
        }
      });

      successData.forEach((data) => {
        const parameter = data["assets"];
        if (parameter !== null) {
          setAssetWorth((prevAsset) => {
            const newAsset = [...prevAsset];
            const lastItem = newAsset[newAsset.length - 1];
            if (parameter === 0) lastItem.Yes += 1;
            else if (parameter === 1) lastItem.Moderate += 1;
            else if (parameter === 2) lastItem.No += 1;
            return newAsset;
          });
        }
      });

      successData.forEach((data) => {
        const parameter = data["capital"];
        if (parameter !== null) {
          setCapitalWorth((prevAsset) => {
            const newAsset = [...prevAsset];
            const lastItem = newAsset[newAsset.length - 1];
            if (parameter === 0) lastItem.Y += 1;
            else if (parameter === 1) lastItem.M += 1;
            else if (parameter === 2) lastItem.N += 1;
            return newAsset;
          });
        }
      });

      successData.forEach((data) => {
        const parameter = data["is_gst_paid"];
        if (parameter !== null) {
          setGstPaid((prevGST) => {
            const newGST = [...prevGST];
            const lastItem = newGST[newGST.length - 1];
            if (parameter === true) lastItem.yes += 1;
            else if (parameter === false) lastItem.no += 1;
            return newGST;
          });
        }
      });

      setOutStanding(totalSumOustanding);
      setPendingReceivables(totalSumRecievable);
      setTotalCustomer(totalCustomer);
      setDsoAverageDays(dsoAverageDays);
      setReachability(reachabilityPercentage);
      setReachabilityCustomerCount(reachabilityCustomerCount);
      setLegalProceedings(noOfLegalProceeding);
      setPartnersRatings([...successData]);
      setOverallDetails({ ...getOverallDetailsOfUser(successData) });
    }
  }, [ratingData]);

  const getAvatarColor = (avgRating) => {
    if (avgRating >= 7) return "green";
    if (avgRating >= 5) return "orange"; 
    if (avgRating >= 1) return "red"; 
    return "grey"; 
  };

  return (
    <Box sx={{ display: "flex" }}>
      <Box component="main" sx={{ flexGrow: "1", p: 3 }} mt={8}>
        <Grid container>
          <Grid xs={6} sm={6} md={6} lg={6}>
            <h2 style={{ fontWeight: "bold", fontSize: "30px" }}>
              Rating Details of{" "}
              <span className="customer-name">{`${ratingData?.user?.business?.name}`}</span>
            </h2>
          </Grid>
          <Grid xs={6} sm={6} md={6} lg={6}>
            <div style={{ textAlign: "right" }}>
              <button
                onClick={() => navigate(-1)}
                className="back"
                style={{
                  backgroundColor: "transparent",
                  display: "flex",
                  alignItems: "center",
                  color: "rgb(68, 72, 133)",
                  cursor: "pointer",
                  float: "right",
                }}
              >
                <KeyboardBackspaceIcon /> Back
              </button>
            </div>
          </Grid>
        </Grid>

        <Grid container mt={-1} rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }} xs={12} spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={4}>
            <div className="cardone">
              <PeopleIcon />
              {`No. of Ratings Received : ${ratingData?.ratings_received}`}
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={4}>
            <div className="card2">{`No. of Ratings Given : ${ratingData?.ratings_given}`}</div>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={4}>
            <div className="card3">{`Total Number of Disputes : ${ratingData?.dispute_count}`}</div>
          </Grid>
        </Grid>

        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Grid container spacing={2} lg={12}>
            <Grid item xs={12} sm={6} md={6} lg={4}>
              <div className="rectangle">
                <h2 style={{ fontWeight: "bold", fontSize: "25px", textAlign: "center" }}>
                  Overall Rating for {`${ratingData?.user?.business?.name}`}
                </h2>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <Avatar
                    sx={{
                      bgcolor: getAvatarColor(overallDetails.avgRating),
                      color: 'white',
                      width: "55px",
                      height: "55px",
                      fontSize: "15px",
                    }}
                  >
                    {overallDetails.avgRating}/10
                  </Avatar>
                </div>

                <Typography style={{ fontWeight: "bold", marginTop: "5px", fontSize: "20px" }} className="riv">
                  Total : {partnersRatings.length} Ratings
                </Typography>
                <div className="partnerdetailsrating">
                  <Ratingcard
                    overallDetails={overallDetails}
                    partnerName={`for ${ratingData?.user?.business?.name}`}
                    partnersRatings={partnersRatings}
                  />
                </div>
              </div>
            </Grid>

            <Grid container lg={8} spacing={4} style={{ marginLeft: "-3px", marginTop: "-0px", height: "725px" }}>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <OutstandingLoans
                  outstanding={outStanding}
                  totalCustomer={totalCustomer}
                  dsoAverageDays={Math.round(dsoAverageDays)}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <RecoveryProbability recoveryProbability={recoveryProbability} />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} style={{ marginTop: "-150px" }}>
              <LegalProceedings legalProceedings={legalProceedings} />
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={6} style={{ marginTop: "-150px" }}>
                <Reachability
                  reachability={reachability}
                  customers={reachabilityCustomerCount}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} style={{ marginTop: "-180px" }}>
                <PendingReceivables pendingReceivables={pendingReceivables} />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} style={{ marginTop: "-180px" }}>
                <IsGSTPaid isGSTPaid={gstPaid} />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} style={{ marginTop: "-200px" }}>
                <CapitalWorth capitalWorth={capitalWorth} />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} style={{ marginTop: "-200px" }}>
                <AssetWorth assetWorth={assetWorth} />
              </Grid>
            </Grid>
          </Grid>
        </Box>

        <Grid container spacing={1} justifyContent={"center"}>
          <Grid item xs={12} sm={10}> 
            <Comments comments={comments} />
          </Grid>
        </Grid>

      </Box>
    </Box>
  );
};

export default UserRatingDetails;

