import React, { useState } from "react";
import "./Partnerdetails.scss";
import * as Yup from "yup";
import SideBar from "../../components/sidebar";
import Ratingcard from "../../components/ratingcard";
import { Formik, Form, Field } from "formik";
import PeopleIcon from "@mui/icons-material/People";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import {
  getMyPartnerRatingsAction,
  clearGetMyPartnerRatingsActionData, postLinkedReationActon
} from "../../store/actions";
import { toast } from "react-toastify";
import { dispatchAll, getOverallDetailsOfUser } from "../../utils";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, } from 'recharts';


import {
  Dialog,
  DialogContent,
  Button,
  Typography,
  IconButton,
  Box,
  Grid,
  useMediaQuery,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import Avatar from "@mui/material/Avatar";
import { deepOrange } from "@mui/material/colors";
import Comments from "../../components/comments";
import OutstandingLoans from "../../components/outstandings";
import PendingReceivables from "../../components/pendingReceivables";
import LegalProceedings from "../../components/legalProceedings";
import Reachability from "../../components/reachability";
import RecoveryProbability from "../../components/recoveryProbability";
import IsGSTPaid from "../../components/isgstpaid";
import CapitalWorth from "../../components/capitalWorth";
import AssetWorth from "../../components/assetWoth";
import { over } from "lodash";
import ReactSpeedometer from "react-d3-speedometer";
import { styled } from "@mui/material/styles";
import { left } from "@popperjs/core";

const Index = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const [show, setShow] = useState(false);
  const [avgRatingTrend, setAvgRatingTrend] = useState([]);

  const [selectedAssocPartner] = React.useState(
    location.state.selectedAssocPartner
  );
  const [partnersRatings, setPartnersRatings] = React.useState([]);
  const [overallDetails, setOverallDetails] = React.useState({
    overAllRatings: {
      1: 0.0,
      2: 0.0,
      3: 0.0,
      4: 0.0,
      5: 0.0,
      6: 0.0,
      7: 0.0,
      8: 0.0,
      9: 0.0,
      10: 0.0,
    },
    totalAmountBalance: 0,
    totalCustomers: 0,
    totalPartners: 0,
    avgRating: 0,
  });
  const { successData:PartnerRatingData, error, errorInfo } =useSelector((state) => state.getMyPartnerRatingsData) || {};
  const business_partner_assoc_id = selectedAssocPartner?.id;
  const isMobile = useMediaQuery("(max-width: 767px)");
  const [outStanding, setOutStanding] = React.useState("");
  const [pendingReceivables, setPendingReceivables] = React.useState("");
  const [pendingCount,setPendingCount] = React.useState(0);
  const [totalCustomer, setTotalCustomer] = React.useState(0);
  const [dsoAverageDays, setDsoAverageDays] = React.useState(0);
  const [legalProceedings, setLegalProceedings] = React.useState(0);
  const [reachability, setReachability] = React.useState(0);
  const [reachabilityCustomerCount, setReachabilityCustomerCount] =
    React.useState(0);
  const [comments, setComments] = React.useState([
    {
      heading: "",
      comment: "",
    },
  ]);
  const [recoveryProbability, setRecoveryProbability] = React.useState([
    {
      yes: 0,
      yes_with_multiple_attempts: 0,
      no: 0,
      difficult: 0,
    },
  ]);

  React.useEffect(() => {
    return () => dispatchAll([clearGetMyPartnerRatingsActionData], dispatch);
  }, [dispatch]);
  React.useEffect(() => {
    dispatch(
      getMyPartnerRatingsAction({
        endPoint: `getPartnerRatings/?partner_id=${business_partner_assoc_id}`,
      })
    );
  }, [dispatch, business_partner_assoc_id]);

  React.useEffect(() => {
    if (PartnerRatingData) {
      let successData = PartnerRatingData.data
      // Define a mapping of parameters to values for total outstanding
      const parameterToValue = {
        0: 0,
        1: 100000,
        2: 350000,
        3: 750000,
        4: 1500000,
        5: 3500000,
        6: 7500000,
        7: 10000000,
      };

      // parameter for dso value
      const dosParameter = {
        0: 0,
        1: 30,
        2: 60,
        3: 90,
        4: 180,
        5: 365,
        6: 730,
      };
      // Initialize reachabilityCustomerCount to 0
      let reachabilityCustomerCount = 0;
      let noOfLegalProceeding = 0;
      let reachabilityPercentage = 0;

      // Count the number of "Yes" values in the filtered data
      const yesCount = successData.reduce((count, data) => {
        const parameter = data["reachability"];
        if (parameter !== null) {
          if (parameter === 0) {
            return count + 1;
          }
        }
        return count;
      }, 0);

      // Count the number of "Yes" values in the filtered data
      const difficultCount = successData.reduce((count, data) => {
        const parameter = data["reachability"];
        if (parameter !== null) {
          if (parameter === 0) {
            return count + 1;
          }
        }
        return count;
      }, 0);

      // Calculate the reachabilityCustomerCount
      successData.forEach((data) => {
        if (data["reachability"] !== null) {
          reachabilityCustomerCount++;
        }
      });

      if (yesCount) {
        // Calculate the reachability percentage
        reachabilityPercentage = (yesCount / reachabilityCustomerCount) * 100;
      } else if (difficultCount) {
        // Calculate the reachability percentage
        reachabilityPercentage =
          (difficultCount / reachabilityCustomerCount) * 50;
      }

      const totalDso = successData.reduce((sum, data) => {
        const parameter = data["dso"];
        return sum + dosParameter[parameter];
      }, 0);

      // dso average days
      const dsoAverageDays = totalDso / successData.length;

      // current total oustanding of all customers
      const totalSumOustanding = successData.reduce((sum, data) => {
        const parameter = data["total_outstanding"];

        if (parameter === 0) return sum;
        return sum + parameterToValue[parameter];
      }, 0);

      // calculating total customer
      const totalCustomers = successData.length;

      // current total recievable of all customers
      const totalSumRecievable = successData.reduce((acc, data) => {
        const parameter = data["pending_receivables"];     
        if (parameter === 0) return acc;
        return {
          sum: acc.sum + parameterToValue[parameter],
          count: acc.count + 1
        };
      }, { sum: 0, count: 0 });

      // this is used for comment exctracting all feilds it include heading and comment
      successData.map((data) => {
        const userType = data["business_partner_main"]["user_type"]["name"];
        const industryType =
          data["business_partner_main"]["business"]["industry"]["name"];
        const location =
          data["business_partner_main"]["business"]["city"] +
          "," +
          data["business_partner_main"]["business"]["state"];
        const heading = `${industryType} ${userType} from ${location} `;
        const comment = data["comments"];

        if (comment && heading) {
          setComments((prevComment) => {
            return [...prevComment, { heading: heading, comment: comment }];
          });
        }
        return "";
      });

      successData.forEach((data) => {
        const legalProceeding = data["legal_proceedings"];
        if (legalProceeding) {
          noOfLegalProceeding++;
        }
      });

      setLegalProceedings(noOfLegalProceeding);

      // here we find the recovery probability of all customers
      successData.forEach((data) => {
        const parameter = data["recovery_probability"];
        if (parameter !== null) {
          setRecoveryProbability((prevRecovery) => {
            const newRecovery = [...prevRecovery];
            const lastItem = newRecovery[newRecovery.length - 1];

            if (parameter === 0) {
              lastItem.yes += 1;
            } else if (parameter === 1) {
              lastItem.yes_with_multiple_attempts += 1;
            } else if (parameter === 2) {
              lastItem.difficult += 1;
            } else if (parameter === 3) {
              lastItem.no += 1;
            }

            return newRecovery;
          });
        }
      });

      // setting the values which gathered
      setOutStanding(totalSumOustanding);
      setPendingReceivables(totalSumRecievable.sum);
      setPendingCount(totalSumRecievable.count);
      setTotalCustomer(totalCustomers);
      setDsoAverageDays(dsoAverageDays);
      setReachability(reachabilityPercentage);
      setReachabilityCustomerCount(reachabilityCustomerCount);
      setPartnersRatings([...successData]);
      setOverallDetails({ ...getOverallDetailsOfUser(successData) });
    }
  }, [PartnerRatingData]);

  React.useEffect(() => {
    if (error) {
      toast.success(errorInfo);
    }
  }, [error, errorInfo]);

  const handleClose = () => {
    setShow(false);
  };

  const familyRelations = [
    { value: "Spouse", label: "Spouse" },
    { value: "Partner/Director", label: "Partner/Director" },
    { value: "Son", label: "Son" },
    { value: "Daughter", label: "Daughter" },
    { value: "Father", label: "Father" },
    { value: "Mother", label: "Mother" },
    { value: "Sibling", label: "Sibling" },
    { value: "Grandparent", label: "Grandparent" },
    { value: "Aunt/Uncle", label: "Aunt/Uncle" },
    { value: "Cousin", label: "Cousin" },
    { value: "In-Law", label: "In-Law" }
  ];


  const validationSchema = Yup.object({
    panno: Yup.string().required("PAN No. is required")
      .matches(/^[A-Z]{5}\d{4}[A-Z]$/, 'Invalid PAN number'),
    confirmpanno: Yup.string()
      .oneOf([Yup.ref('panno'), null], 'PAN numbers must match')
      .required("Please confirm your PAN number"),
    name: Yup.string().required("Name is required"),
    relation: Yup.string().required("Relation is required"),
  });
  const initialValues = {
    panno: "",
    name: "",
    relation: "",
    confirmpanno: "",
  };

  const handleSubmit = async (values, actions) => {
    try {
      await dispatch(
        postLinkedReationActon({
          user_id_main: business_partner_assoc_id,
          user_pan_assoc: values.panno,
          relationship: values.relation,
          name: values.name,

        })
      ).unwrap();
      actions.resetForm();
      toast.success("Successfully requested to add a relation to your partner.")
      setShow(false)
    } catch (err) {
      toast.error(err.message || 'An error occurred');
    }

  };
  const handleChange = (event, setFieldValue) => {
    const { name, value } = event.target;
    setFieldValue(name, value.toUpperCase());
  };
  
  const avgRatingdata = PartnerRatingData?.avg_rating_trend;
  // Replace the existing formattedData definition with:
  const formattedData = avgRatingdata?.map(item => ({
    date: new Date(item.date).toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
    }),
    avg_score: item.avg_score
  })).sort((a, b) => {
    const dateA = new Date(a.date);
    const dateB = new Date(b.date);
    return dateB - dateA;  // Chronological order
  });
  


  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div style={{ width:"85px",height:"40px", backgroundColor: 'white', padding: '1px', border: '1px solid #ccc', borderRadius: '4px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' }}>
          <p  style={{fontSize:"10px" ,margin:"1px"}} >{`Date: ${payload[0].payload.date}`}</p>
          <p style={{fontSize:"10px",margin:"1px"}}>{`Avg Score: ${payload[0].payload.avg_score.toFixed(2)}`}</p>
        </div>
      );
    }
    return null;
  };
  const SpeedometerWrapper = styled('div')(({ theme }) => ({
    position: 'relative',
    width: '100%',
    maxWidth: '500px',
    margin: '0 auto',
    textAlign: 'center',
    
    '& .gradient-overlay': {
      position: 'absolute',
      width: '217px',
      height: '211px',
      top: '65%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: '50%',
      background: 'linear-gradient(to right, #FF0000 0%, #FF0000 40%, #FFA500 50%, #FFFF00 70%, #00FF00 100%)',
      opacity: 0.4,
      zIndex: 900,
      clipPath: 'path("M 0 0 L 220 0 L 220 110 Q 110 69, 0 110 Z")',
    },
    '& .rating-circle': {
      backgroundColor: '#fff',
      borderRadius: '50%',
      width: '80px',
      height: '85px',
      zIndex: 1001,
      position: 'absolute',
      top: '65%',
      left: '50%',
      transform: 'translate(-50%, -60%)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.4)'
    },
    '@media (max-width: 1024px)': {
      '& .gradient-overlay': {
        width: '220px',
        height: '209px',
      },
    },
    '@media (max-width: 768px)': {
      '& .gradient-overlay': {
        width: '217px',
        height: '216px',
      },
      '& .rating-circle': {
        width: '70px',
        height: '70px',
      }
    },
  
    '@media screen and (width: 820px)': {
      '& .gradient-overlay': {
        width: '219px',
        height: '224px',
        transform: 'translate(-50%, -50%) scale(0.95)',
      },
      '& .rating-circle': {
        width: '65px',
        height: '65px',
        transform: 'translate(-50%, -60%) scale(0.95)',
      },
      '& .speedometer-container': {
        transform: 'scale(0.95)',
      },
    },
    '@media (max-width: 480px)': {
      '& .gradient-overlay': {
        width: '218px',
        height: '213px',
      },
      '& .rating-circle': {
        width: '60px',
        height: '60px',
      }
    },
    '@media (max-width: 320px)': {
      '& .gradient-overlay': {
        width: '250px',
        height: '300px',
      },
      '& .rating-circle': {
        width: '50px',
        height: '50px',
      }
    }
  }));

  return (
    <>
      {isMobile ? (
        <Box sx={{ display: "flex" }}>
          <SideBar />
          <Box component="main" sx={{ flexGrow: "1", p: 3 }} mt={8}>
            <Grid container spacing={1}>
              {/* Header section */}
              <Grid item xs={12}>
                <h2 style={{
                  fontSize: "22px",
                  margin: "10px 0",
                  padding: "0 10px"
                }}>
                  Rating Details of{" "}
                  <span className="customer-name">{`${selectedAssocPartner.business.name}`}</span>
                </h2>
              </Grid>

              {/* Stats cards - Update styling */}
              <Grid container spacing={1} sx={{ padding: '10px' }} mb={2}>
                <Grid item xs={12}>
                  <div className="cardone" style={{
                    padding: '12px',
                    margin: '5px 0',
                    borderRadius: '8px',
                    fontSize: '14px'
                  }}>
                    <PeopleIcon sx={{ fontSize: '20px' }} />
                    {`No. of Ratings Received : ${PartnerRatingData?.ratings_received}`}
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className="card2" style={{
                    padding: '12px',
                    margin: '5px 0',
                    borderRadius: '8px',
                    fontSize: '14px'
                  }}>
                    {`No. of Ratings Given : ${PartnerRatingData?.ratings_given}`}
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className="card3" style={{
                    padding: '12px',
                    margin: '5px 0',
                    borderRadius: '8px',
                    fontSize: '14px'
                  }}>
                    {`Total Number of Disputes : ${PartnerRatingData?.dispute_count}`}
                  </div>
                </Grid>
              </Grid>

              {/* Rating section */}
              <Grid item xs={12}>
              <div style={{ transition: 'all 0.3s ease', backgroundColor: '#fff',borderRadius: '8px',  padding: '8px', boxShadow: '0 4px 10px rgba(0,0,0,4)' }}>
                  <Typography className="com" style={{ fontSize: "18px", color: "#000", transition: 'all 0.3s ease' }}>
                    <b>{`Overall Rating ${selectedAssocPartner.business.name}`}</b>
                  </Typography>

                  <SpeedometerWrapper>
                    <div className="gradient-overlay" />
                    <div className="rating-circle">
                      <div>
                        <h2 style={{ margin: 0, fontSize: 'clamp(16px, 4vw, 24px)' }}>
                          <b>{`${overallDetails.avgRating || 0}`}</b>
                        </h2>
                      </div>
                    </div>

                    <ReactSpeedometer
                      width={300}
                      height={250}
                      containerClassName="speedometer-container"
                      value={overallDetails.avgRating || 0}
                      minValue={0}
                      maxValue={10}
                      needleColor="black"
                      segments={39}
                      needleHeightRatio={0.7}
                      ringWidth={10}
                      needleTransition="easeElastic"
                      needleTransitionDuration={3000}
                      valueTextFontSize="28px"
                      textColor="#fff"
                      paddingHorizontal={5}
                      paddingVertical={5}
                      valueBox={{
                        background: 'linear-gradient(180deg, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0.3) 100%)',
                        boxShadow: 'inset 0 4px 8px rgba(255,255,255,0.9), inset 0 -4px 8px rgba(0,0,0,0.1)',
                        borderRadius: '50%'
                      }}
                      containerStyle={{
                        background: 'linear-gradient(180deg, rgba(255,255,255,0.2) 0%, transparent 50%)',
                        borderRadius: '50%'
                      }}
                      customSegmentStops={[
                        0, 0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2, 2.25, 2.5, 2.75, 3, 3.25, 3.5, 3.75, 4, 4.25, 4.5, 4.75, 5, 5.25, 5.5, 5.75, 6, 6.25, 6.5, 6.75, 7, 7.25, 7.5, 7.75, 8, 8.25, 8.5, 8.75, 9, 9.5, 9.75, 10
                      ]}
                      segmentColors={[
                        "#FF0000", "#FF0800", "#FF1000", "#FF1800", "#FF2000",
                        "#FF2800", "#FF3000", "#FF3800", "#FF4000", "#FF4800",
                        "#FF5000", "#FF5800", "#FF6000", "#FF6800", "#FF7000",
                        "#FF7800", "#FF8000", "#FF8800", "#FF9000", "#FF9800",
                        "#FFA000", "#FFA800", "#FFB000", "#FFB800", "#FFC000",
                        "#FFC800", "#FFD000", "#FFD800", "#FFE000", "#FFE800",
                        "#FFFF00", "#EAFF00", "#D4FF00", "#BEFF00", "#A8FF00",
                        "#92FF00", "#7CFF00", "#66FF00", "#50FF00", "#3AFF00"
                      ]}
                      customSegmentLabels={Array(39).fill({ text: "", position: "OUTSIDE" })}
                      valueTextFontWeight="bold"
                      fluidWidth={false}
                      startColor="transparent"
                      endColor="transparent"
                      labelFontSize="12px"
                    />
                    <Typography className="riv" style={{ marginTop: '-70px', position: 'relative', zIndex: 1000 }}>
                      <span style={{
                        fontWeight: "bold",
                        fontSize: "clamp(14px, 3vw, 18px)",
                        backgroundColor: "#2b4257",
                        borderRadius: "20px",
                        padding: "5px 10px",
                        color: "white"
                      }}>
                        Total: {partnersRatings.length} Ratings
                      </span>
                    </Typography>
                  </SpeedometerWrapper>
                  <div className="partnerdetailsrating">
                    <Ratingcard
                      overallDetails={overallDetails}
                      partnerName={`for ${selectedAssocPartner.business.name}`}
                      partnersRatings={partnersRatings}
                    />
                  </div>
                </div>
                <Grid container spacing={1} justifyContent="center">
                  <Grid item xs={12} sm={10} style={{ marginTop: '10%' }}>
                    <div
                      style={{
                        textAlign: "center",
                        // display: "flex",
                        justifyContent: "center",
                        gap: "20px",
                        alignItems: "center",
                      }}
                    >
                      <p style={{ fontWeight: "bold", fontSize: "20px" }}>
                        Add related PAN of{" "}
                        <span className="customer-name">
                          {selectedAssocPartner.business.name}
                        </span>{" "}
                        ?
                      </p>
                      <Button
                        type="button"
                        onClick={() => setShow(true)}
                        variant="contained"
                        sx={{
                          marginBottom: '15px',
                          background: 'linear-gradient(90deg, #65A3DD 0%, #1584D4 48.6%, #5D8FBD 100%)',
                          color: "#fff",
                          "&:hover": {
                            backgroundColor: "#2b4257",
                            color: "#fff",
                          },
                        }}
                      >
                        Add
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </Grid>

              {/* Chart section */}
              <Grid item xs={12}>
                <Box sx={{
                  backgroundColor: '#fff',
                  borderRadius: '8px',
                  padding: '20px',
                  boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
                }}>
                  {formattedData && formattedData.length > 0 ? (
                            <ResponsiveContainer width="100%" height={300}>
                            <LineChart
                              data={formattedData}
                              margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
                            >
                              <CartesianGrid strokeDasharray="3 3" />
                              <XAxis
                                dataKey="date"
                                orientation="bottom"
                                reversed={true}  // This makes it flow right to left
                              />
                              <YAxis
                                domain={[0, 10]}
                                ticks={[0, 5, 10]}
                                orientation="left"
                                yAxisId="left"
                              />
                              <Tooltip content={<CustomTooltip />} />
                              <Line
                                type="monotone"
                                dataKey="avg_score"
                                stroke="#00C49F"
                                strokeWidth={2}
                                dot={{ r: 5, fill: '#00C49F' }}
                                yAxisId="left"
                              />
                            </LineChart>
                          </ResponsiveContainer>
                  ) : (
                    <Typography sx={{
                      textAlign: 'center',
                      fontSize: '16px',
                      color: '#2b4257',
                    }}>
                      No ratings available to display.
                    </Typography>
                  )}
                </Box>
              </Grid>

              {/* Stats cards - Make them full width on mobile */}
              <Grid container spacing={1} sx={{ padding: '10px' }}>
              <Grid item xs={12}>
                  <PendingReceivables 
                  pendingReceivables={pendingReceivables}
                  totalCustomer={pendingCount} 
                  />
                </Grid>
                <Grid item xs={12}>
                  <OutstandingLoans
                    outstanding={outStanding}
                    totalCustomer={totalCustomer}
                    pendingCount={pendingCount}
                    dsoAverageDays={dsoAverageDays}
                  />
                </Grid>
                <Grid item xs={12}>
                  <RecoveryProbability
                    recoveryProbability={recoveryProbability}
                  />
                </Grid>
                <Grid item xs={12}>
                  <LegalProceedings
                    legalProceedings={legalProceedings}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Reachability
                    reachability={reachability}
                    customers={reachabilityCustomerCount}
                  />
                </Grid>
              </Grid>

              {/* Comments section */}
              <Grid item xs={12}>
                <Box sx={{
                  padding: '10px',
                  margin: '10px',
                  backgroundColor: '#fff',
                  borderRadius: '8px'
                }}>
                  <Comments comments={comments} />
                </Box>
              </Grid>
            </Grid>

          </Box>

          <Dialog open={show} onClose={handleClose} fullWidth maxWidth="sm">
            <Box position="relative" sx={{ p: 2 }}>
              <IconButton
                onClick={handleClose}
                sx={{
                  position: "absolute",
                  top: 5,
                  right: 8,
                  "&:hover": { backgroundColor: "none", color: "red" },
                }}
              >
                <CloseIcon />
              </IconButton>

              <DialogContent>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  sx={{}}
                >
                  <Typography
                    gutterBottom
                    sx={{
                      mt: 2,
                      fontWeight: "bold",
                      color: "#2b4257",
                      fontSize: isMobile ? "24px" : "30px",
                      fontFamily: "Arial",
                    }}
                  >
                    Add Related Pan Details
                  </Typography>
                  <Box
                    sx={{
                      maxWidth: isMobile ? "100%" : 600,
                      width: isMobile ? "90%" : 400,
                      margin: "auto",
                      padding: isMobile ? 1 : 2,
                      border: "1px solid #ccc",
                      borderRadius: 4,
                    }}
                  >
                    <Formik
                      initialValues={initialValues}
                      validationSchema={validationSchema}
                      onSubmit={handleSubmit}

                    >
                      {({ errors, touched, setFieldValue }) => (
                        <Form className="formik-form">
                          <div className="form-group-relatedform">
                            <label htmlFor="panno">PAN NUMBER </label>
                            <Field
                              placeHolder="Enter Relation PAN Num"
                              className="form-input"
                              name="panno"
                              type="text"
                              maxlength="10"
                              onChange={(event) => handleChange(event, setFieldValue)}
                            />
                            {errors.panno && touched.panno ? (
                              <div className="error-message">
                                {errors.panno}
                              </div>
                            ) : null}
                          </div>
                          <div className="form-group-relatedform">
                            <label htmlFor="confirmpanno"> Confirm PAN NUMBER </label>
                            <Field
                              placeHolder="Confirm Relation PAN Num"
                              className="form-input"
                              name="confirmpanno"
                              type="text"
                              maxlength="10"
                              onChange={(event) => handleChange(event, setFieldValue)}
                            />
                            {errors.confirmpanno && touched.confirmpanno ? (
                              <div className="error-message">
                                {errors.confirmpanno}
                              </div>
                            ) : null}
                          </div>

                          <div className="form-group-relatedform">
                            <label htmlFor="name">Name</label>
                            <Field
                              placeHolder="Enter Relation Name"
                              name="name"
                              type="text"
                              className="form-input"
                            />
                            {errors.name && touched.name ? (
                              <div className="error-message">{errors.name}</div>
                            ) : null}
                          </div>

                          <div className="form-group-relatedform">
                            <label htmlFor="relation">Relation</label>
                            <Field
                              name="relation"
                              as="select"
                              className="form-input"
                            >
                              <option value="">Select The RelationShip</option>
                              {familyRelations.map((relation) => (
                                <option
                                  key={relation.value}
                                  value={relation.value}
                                >
                                  {relation.label}
                                </option>
                              ))}
                            </Field>
                            {errors.relation && touched.relation ? (
                              <div className="error-message">
                                {errors.relation}
                              </div>
                            ) : null}
                          </div>

                          <Button sx={{ marginTop: "5px" ,background:'linear-gradient(90deg, #65A3DD 0%, #1584D4 48.6%, #5D8FBD 100%)'}} type="submit">Submit</Button>
                        </Form>
                      )}
                    </Formik>
                  </Box>
                </Box>
              </DialogContent>
            </Box>
          </Dialog>
        </Box>
      ) : (
        <Box sx={{ display: "flex" }}>
          <SideBar />
          <Box
            component="main"
            minWidth={100}
            sx={{ flexGrow: "1", p: 3 }}
            mt={8}
          >
            <Grid container>
              <Grid xs={6} sm={6} md={6} lg={6}>
                <h2 style={{ fontSize: "30px" }}>
                  Rating Details of{" "}
                  <span className="customer-name">{`${selectedAssocPartner.business.name}`}</span>
                </h2>
              </Grid>
              <Grid xs={6} sm={6} md={6} lg={6}>
                <div style={{ textAlign: "right" }}>
                  <button
                    onClick={() => navigate(-1)}
                    className="back"
                    style={{
                      backgroundColor: "transparent",
                      display: "flex",
                      alignItems: "center",
                      color: "#2b4257",
                      cursor: "pointer",
                      float: "right",
                    }}
                  >
                    <KeyboardBackspaceIcon /> Back
                  </button>
                </div>
              </Grid>
            </Grid>

            <Grid
              container
              mt={-1}
              mb={2}
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 1, md: 1 }}
              xs={12}
              spacing={2}
            >
              <Grid item xs={12} sm={12} md={12} lg={4}>
                {" "}
                <div className="cardone">
                  <PeopleIcon />
                  {`No. of Ratings Received : ${PartnerRatingData?.ratings_received}`}
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={4}>
                {" "}
                <div className="card2">{`No. of Ratings Given : ${PartnerRatingData?.ratings_given}`}</div>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={4}>
                {" "}
                <div className="card3">{`Total Number of Disputes : ${PartnerRatingData?.dispute_count}`}</div>
              </Grid>
            </Grid>

              <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
                <Grid container spacing={3}>
                  {/* Rating Card Section */}
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <div style={{ transition: 'all 0.3s ease', backgroundColor: '#fff',borderRadius: '8px',  padding: '8px', boxShadow: '0 4px 10px rgba(0,0,0,4)' }}>
                      <Typography className="com" style={{ fontSize: "18px", color: "#000", transition: 'all 0.3s ease' }}>
                        <b>{`Overall Rating ${selectedAssocPartner.business.name}`}</b>
                      </Typography>

                      <SpeedometerWrapper>
                        <div className="gradient-overlay" />
                        <div className="rating-circle">
                          <div>
                            <h2 style={{ margin: 0, fontSize: 'clamp(16px, 4vw, 24px)' }}>
                              <b>{`${overallDetails.avgRating || 0}`}</b>
                            </h2>
                          </div>
                        </div>

                        <ReactSpeedometer
                          width={300}
                          height={250}
                          containerClassName="speedometer-container"
                          value={overallDetails.avgRating || 0}
                          minValue={0}
                          maxValue={10}
                          needleColor="black"
                          segments={39}
                          needleHeightRatio={0.7}
                          ringWidth={10}
                          needleTransition="easeElastic"
                          needleTransitionDuration={3000}
                          valueTextFontSize="28px"
                          textColor="#fff"
                          paddingHorizontal={5}
                          paddingVertical={5}
                          valueBox={{
                            background: 'linear-gradient(180deg, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0.3) 100%)',
                            boxShadow: 'inset 0 4px 8px rgba(255,255,255,0.9), inset 0 -4px 8px rgba(0,0,0,0.1)',
                            borderRadius: '50%'
                          }}
                          containerStyle={{
                            background: 'linear-gradient(180deg, rgba(255,255,255,0.2) 0%, transparent 50%)',
                            borderRadius: '50%'
                          }}
                          customSegmentStops={[
                            0, 0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2, 2.25, 2.5, 2.75, 3, 3.25, 3.5, 3.75, 4, 4.25, 4.5, 4.75, 5, 5.25, 5.5, 5.75, 6, 6.25, 6.5, 6.75, 7, 7.25, 7.5, 7.75, 8, 8.25, 8.5, 8.75, 9, 9.5, 9.75, 10
                          ]}
                          segmentColors={[
                            "#FF0000", "#FF0800", "#FF1000", "#FF1800", "#FF2000",
                            "#FF2800", "#FF3000", "#FF3800", "#FF4000", "#FF4800",
                            "#FF5000", "#FF5800", "#FF6000", "#FF6800", "#FF7000",
                            "#FF7800", "#FF8000", "#FF8800", "#FF9000", "#FF9800",
                            "#FFA000", "#FFA800", "#FFB000", "#FFB800", "#FFC000",
                            "#FFC800", "#FFD000", "#FFD800", "#FFE000", "#FFE800",
                            "#FFFF00", "#EAFF00", "#D4FF00", "#BEFF00", "#A8FF00",
                            "#92FF00", "#7CFF00", "#66FF00", "#50FF00", "#3AFF00"
                          ]}
                          customSegmentLabels={Array(39).fill({ text: "", position: "OUTSIDE" })}
                          valueTextFontWeight="bold"
                          fluidWidth={false}
                          startColor="transparent"
                          endColor="transparent"
                          labelFontSize="12px"
                        />
                        <Typography className="riv" style={{ marginTop: '-70px', position: 'relative', zIndex: 1000 }}>
                          <span style={{
                            fontWeight: "bold",
                            fontSize: "clamp(14px, 3vw, 18px)",
                            backgroundColor: "#2b4257",
                            borderRadius: "20px",
                            padding: "5px 10px",
                            color: "white"
                          }}>
                            Total: {partnersRatings.length} Ratings
                          </span>
                        </Typography>
                      </SpeedometerWrapper>
                      <div className="partnerdetailsrating">
                        <Ratingcard
                          overallDetails={overallDetails}
                          partnerName={`for ${selectedAssocPartner.business.name}`}
                          partnersRatings={partnersRatings}
                        />
                      </div>
                    </div>
                    <Grid container spacing={1} justifyContent="center">
                      <Grid item xs={12} sm={10} style={{ marginTop: '10%' }}>
                        <div
                          style={{
                            textAlign: "center",
                            // display: "flex",
                            justifyContent: "center",
                            gap: "10px",
                            alignItems: "center",
                          }}
                        >
                          <p style={{ fontWeight: "bold", fontSize: "20px" }}>
                            Add related PAN of{" "}
                            <span className="customer-name">
                              {selectedAssocPartner.business.name}
                            </span>{" "}
                            ?
                          </p>
                          <Button
                            type="button"
                            onClick={() => setShow(true)}
                            variant="contained"
                            sx={{
                              marginBottom: '15px',
                              background: 'linear-gradient(90deg, #65A3DD 0%, #1584D4 48.6%, #5D8FBD 100%)',
                              color: "#fff",
                              "&:hover": {
                                backgroundColor: "#2b4257",
                                color: "#fff",
                              },
                            }}
                          >
                            Add
                          </Button>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* Chart and Details Section */}
                  <Grid item xs={12} sm={12} md={8} lg={8}>
                    <Grid container spacing={2}>
                      {/* Line Chart */}
                      <Grid item xs={12}>
                        <Box sx={{
                          backgroundColor: '#fff',
                          borderRadius: '8px',
                          padding: '20px',
                          boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
                        }}>
                          {formattedData && formattedData.length > 0 ? (
                            // Then update the LineChart component
                            <ResponsiveContainer width="100%" height={300}>
                              <LineChart
                                data={formattedData}
                                margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
                              >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis
                                  dataKey="date"
                                  orientation="bottom"
                                  reversed={true}  // This makes it flow right to left
                                />
                                <YAxis
                                  domain={[0, 10]}
                                  ticks={[0, 5, 10]}
                                  orientation="left"
                                  yAxisId="left"
                                />
                                <Tooltip content={<CustomTooltip />} />
                                <Line
                                  type="monotone"
                                  dataKey="avg_score"
                                  stroke="#00C49F"
                                  strokeWidth={2}
                                  dot={{ r: 5, fill: '#00C49F' }}
                                  yAxisId="left"
                                />
                              </LineChart>
                            </ResponsiveContainer>

                          ) : (
                            <Typography sx={{
                              textAlign: 'center',
                              fontSize: '16px',
                              color: '#2b4257',
                            }}>
                              No.of Ratings Not Available to Display Graph.
                            </Typography>
                          )}
                        </Box>
                      </Grid>

                      {/* Critical Dues Section */}
                      <Grid item xs={12}>
                        <PendingReceivables 
                        pendingReceivables={pendingReceivables}
                        totalCustomer={pendingCount} 
                        />
                      </Grid>

                      {/* Two Column Layout for Other Components */}
                      <Grid item xs={12} sm={6}>
                        <OutstandingLoans
                          outstanding={outStanding}
                          totalCustomer={totalCustomer}
                          pendingCount={pendingCount}
                          dsoAverageDays={Math.round(dsoAverageDays)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <RecoveryProbability recoveryProbability={recoveryProbability} />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <LegalProceedings legalProceedings={legalProceedings} />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Reachability
                          reachability={reachability}
                          customers={reachabilityCustomerCount}
                        />
                      </Grid>

                      {/* Comments Section */}
                      <Grid item xs={12}>
                        <Box sx={{
                          padding: '12px',
                          backgroundColor: '#fff',
                          borderRadius: '8px',
                          boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                          '&::-webkit-scrollbar': {
                            width: '8px'
                          },
                          '&::-webkit-scrollbar-thumb': {
                            backgroundColor: '#888',
                            borderRadius: '4px'
                          }
                        }}>
                          <Comments comments={comments} />
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>

            <Dialog open={show} onClose={handleClose} fullWidth maxWidth="sm">
              <Box position="relative" sx={{ p: 2 }}>
                <IconButton
                  onClick={handleClose}
                  sx={{
                    position: "absolute",
                    top: 5,
                    right: 8,
                    "&:hover": { backgroundColor: "none", color: "red" },
                  }}
                >
                  <CloseIcon />
                </IconButton>

                <DialogContent>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    sx={{}}
                  >
                    <Typography
                      gutterBottom
                      sx={{
                        mt: 2,
                        fontWeight: "bold",
                        color: "#2b4257",
                        fontSize: isMobile ? "24px" : "30px",
                        fontFamily: "Arial",
                      }}
                    >
                      Add Related Pan Details
                    </Typography>
                    <Box
                      sx={{
                        maxWidth: isMobile ? "100%" : 600,
                        width: isMobile ? "90%" : 400,
                        margin: "auto",
                        padding: isMobile ? 1 : 2,
                        border: "1px solid #ccc",
                        borderRadius: 4,
                      }}
                    >
                      <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}

                      >
                        {({ errors, touched, setFieldValue }) => (
                          <Form className="formik-form">
                            <div className="form-group-relatedform">
                              <label htmlFor="panno">PAN NUMBER </label>
                              <Field
                                placeHolder="Enter Relation PAN Num"
                                className="form-input"
                                name="panno"
                                type="text"
                                maxlength="10"
                                onChange={(event) => handleChange(event, setFieldValue)}
                              />
                              {errors.panno && touched.panno ? (
                                <div className="error-message">
                                  {errors.panno}
                                </div>
                              ) : null}
                            </div>
                            <div className="form-group-relatedform">
                              <label htmlFor="confirmpanno"> Confirm PAN NUMBER </label>
                              <Field
                                placeHolder="Confirm Relation PAN Num"
                                className="form-input"
                                name="confirmpanno"
                                type="text"
                                maxlength="10"
                                onChange={(event) => handleChange(event, setFieldValue)}
                              />
                              {errors.confirmpanno && touched.confirmpanno ? (
                                <div className="error-message">
                                  {errors.confirmpanno}
                                </div>
                              ) : null}
                            </div>

                            <div className="form-group-relatedform">
                              <label htmlFor="name">Name</label>
                              <Field
                                placeHolder="Enter Relation Name"
                                name="name"
                                type="text"
                                className="form-input"
                              />
                              {errors.name && touched.name ? (
                                <div className="error-message">{errors.name}</div>
                              ) : null}
                            </div>

                            <div className="form-group-relatedform">
                              <label htmlFor="relation">Relation</label>
                              <Field
                                name="relation"
                                as="select"
                                className="form-input"
                              >
                                <option value="">Select The RelationShip</option>
                                {familyRelations.map((relation) => (
                                  <option
                                    key={relation.value}
                                    value={relation.value}
                                  >
                                    {relation.label}
                                  </option>
                                ))}
                              </Field>
                              {errors.relation && touched.relation ? (
                                <div className="error-message">
                                  {errors.relation}
                                </div>
                              ) : null}
                            </div>

                            <Button sx={{ marginTop: "5px",background:'linear-gradient(90deg, #65A3DD 0%, #1584D4 48.6%, #5D8FBD 100%)' }} type="submit">Submit</Button>
                          </Form>
                        )}
                      </Formik>
                    </Box>
                  </Box>
                </DialogContent>
              </Box>
            </Dialog>
          </Box>
        </Box>
      )}
    </>
  );
};

export default Index;
