import React from 'react';
import { AppBar, Toolbar, Box, Button, useTheme, useMediaQuery } from '@mui/material';
import clearbiz3 from '../../assets/clearbiz3.png'; 
import { Link } from 'react-router-dom';

const Header = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  const logoStyle = {
    width: isMobile ? '150px' : isTablet ? '175px' : '200px',
    marginRight: 'auto',
    height: 'auto',
    maxWidth: '100%'
  };

  return (
    <AppBar position="static" style={{ backgroundColor: 'white', color: 'black', boxShadow: 'none' }}>
      <Toolbar sx={{ padding: isMobile ? '10px' : '20px' }}>
        <img src={clearbiz3} alt="ClearBiz Logo" style={logoStyle} />
        <Box style={{ flexGrow: 1 }} />
        <Link to="/Login" style={{ textDecoration: 'none' }}>
          <Button 
            variant="contained" 
            style={{ 
              backgroundColor: '#2b4257', 
              color: 'white',
              borderRadius: '30px',
              height: '40px',
              '&:hover': {
                backgroundColor: '#005f8f',
              }
            }}
          >
            Login/SignUp
          </Button>
        </Link>
      </Toolbar>
    </AppBar>
  );
};


export default Header;