import React, { useEffect, useState } from "react";
import Ratings from "../../components/ratings";
import "../myratings/Myratings.scss";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../components/loader";
import SideBar from "../../components/sidebar";
import Table from "../../components/table";
import {
  getProfileAction,
  clearGetMyRatingActionData,
  getMyRatingAction,
  postRaiseDispute,
  clearPostRaiseDisputeData,
} from "../../store/actions";
import { dispatchAll, getOverallDetailsOfUser } from "../../utils";
import TableData from "../../utils/tableutils";


const Index = () => {
  const {successData: profileData } = useSelector(state => state.profileData) || {};
  console.log('profileData', profileData);
  
  const { successData: postDisputeData } =
    useSelector((state) => state.postRaiseDispute) || {};
  const [partnersRatings, setPartnersRatings] = React.useState([]);
  const [selectedRow, setSelectedRow] = React.useState();
  const [reasonText, setReasonText] = React.useState("");
  const [selectedPartnerUsername, setSelectedPartnerUsername] = useState("");
  const [selectedPartnerUserId, setSelectedPartnerUserId] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [partnerFirstname, setPartnerFirstname] = useState("");

  const options = [
    { value: "NOT_HAVING_ANY_TRANS", label: "I am not having any transaction" },
    {
      value: "HAVE_PENDING_CREDIT_NOTES",
      label: "I have pending credit notes",
    },
    { value: "ALREADY_MADE_THE_PAYMENT", label: "I already made the payment" },
    { value: "OTHERS", label: "Others" },
  ];
  const [selectedReason, setSelectedReason] = React.useState(options[0]);


  const dispatch = useDispatch();
  const [overallDetails, setOverallDetails] = React.useState({
    overAllRatings: { 1: 0.0, 2: 0.0, 3: 0.0, 4: 0.0, 5: 0.0 },
    totalAmountBalance: 0,
    totalCustomers: 0,
    totalPartners: 0,
    avgRating: 0,
  });
  const {
    loading: loadingMyRatings,
    successData,
  } = useSelector((state) => state.getMyRatingData) || {};
  const columns = React.useMemo(() => TableData.myRatings, []);

  React.useEffect(() => {
    return () => dispatchAll([clearGetMyRatingActionData], dispatch);
  }, [dispatch]);
  React.useEffect(() => {
    dispatch(getMyRatingAction());
  }, [dispatch]);

  React.useEffect(() => {
    if (successData) {
      setPartnersRatings([...successData]);
      setOverallDetails({ ...getOverallDetailsOfUser(successData) });
    }
  }, [successData]);

  React.useEffect(() => {
    dispatch(getProfileAction());
  }, [dispatch]);

  React.useEffect(() => {
    if (postDisputeData) {
      setReasonText("");
      const clonedPartnersRatings = JSON.parse(JSON.stringify(partnersRatings));
      clonedPartnersRatings[selectedRow].is_raised_dispute = true;
      setPartnersRatings(clonedPartnersRatings);
      setShowModal(false);
      toast.success("Dispute raised successfully!");
    }
  }, [postDisputeData,selectedRow]);

  useEffect(() => {
    return () => {
      dispatch(clearPostRaiseDisputeData());
    };
  }, [dispatch]);

  const onClickRaiseDispute = (row_id) => {
    setSelectedRow(parseInt(row_id));
    setSelectedPartnerUserId(partnersRatings[row_id]?.user_id || "");
    
    setSelectedPartnerUsername(
      partnersRatings[row_id]?.score_details?.username || ""
    );
    setShowModal(true);
    const firsrname =
    partnersRatings[row_id]?.business_partner_main?.first_name || "";
    setPartnerFirstname(firsrname);
  };

  const handleClose = () => {
    setShowModal(false);
  };
  const onSubmitDisputeData = () => {
    if ( reasonText.trim() === "") {
      toast.error("Error: Reason is required");
    } else {
      const data = {
        reason: reasonText,
        raised_on_user: partnersRatings[selectedRow].business_partner_main.id,
        reason_option: selectedReason.value,
      };
      dispatch(postRaiseDispute(data));
    }
  };

 
  return (
  
      <Box sx={{ display: "flex",minHeight: "90vh" }}>
        <SideBar />
        <Box component="main" sx={{ flexGrow: 1, p: 3 }} mt={8}>
          <Grid item xs={12} sm={12} md={6} lg={3}>
            <Typography variant="h4" className="Myratingsheading" sx={{ mb: 2 }}>
              My Ratings
            </Typography>
          </Grid>
         
         {loadingMyRatings?( <Loader className="loader" />):(
          <Grid container spacing={2}>
            <Grid
              container
              mt={2}
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 1, md: 1 }}
              xs={12}
              spacing={2}
            >
              {/* <Grid item xs={12} sm={12} md={12} lg={12}>
                {" "}
                <div className="abc-card">{`Total Ratings Recieved : ${
                  partnersRatings.filter(
                    (partner) => partner.is_business_exists === true
                  ).length
                }`}</div>
              </Grid> */}
            </Grid>
            {/* <Grid
              container
              rowSpacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              xs={12}
              md={12}
              mt={2}
              style={{ marginTop: "-10px" }}
            > */}
              <Grid item xs={12} sm={12} md={6} lg={4} sx={{  boxShadow: "0px 4px 10px rgba(0, 0, 0, 4)",borderRadius:'15px'}}>
                <div className="partnerdetailrating">
                  <Ratings
                    overallDetails={overallDetails}
                    partnerName={`for ${profileData?.business.name}`}
                    partnersRatings={partnersRatings}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={8} mt={-3}>
                {partnersRatings && (
                  <Table
                    columns={columns}
                    data={partnersRatings}
                    onClickViewDetails={onClickRaiseDispute}
                  />
                )}{" "}
              </Grid>
            {/* </Grid> */}
          </Grid>)}
        </Box>
        <Modal
          open={showModal}
          onClose={handleClose}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <div className="Boxstyle" style={{ borderRadius: "15px" }}>
            <h4 id="modal-title" className="Raised_on">
              Raised on  <span style={{color:'#2b4257',fontFamily:'Ruwudu',fontWeight:700}}>{partnerFirstname}</span>
            </h4>

            <Select
              name="reason"
              className="Raised_on"
              styles={{
                control: (provided) => ({
                  ...provided,
                  width: "100%",
                  marginBottom: "10px",
                  borderRadius: "5px",
                }),
              }}
              value={selectedReason} // Set the initial selected option
              options={options}
              onChange={(selectedOption) => setSelectedReason(selectedOption)} // Update the selectedReason based on the selected option's value
            />
            <TextField
              placeholder="Reason"
              multiline
              value={reasonText}
              rows={6}
              onChange={(e) => setReasonText(e.target.value)}
              fullWidth
              name="reason"
              required
              // disabled={selectedReason !== 'reason4'} is selected
            />

            <div className="raisedbuttoncontainer">
              <button
                style={{background:'linear-gradient(90deg, #65A3DD 0%, #1584D4 48.6%, #5D8FBD 100%)'}}
                type="submit"
                onClick={onSubmitDisputeData}
              >
                Submit
              </button>
            </div>
            <FontAwesomeIcon
              className="raisebuttonclose"
              icon={faTimes}
              onClick={() => setShowModal(false)}
            />
          </div>
        </Modal>
      </Box>
  );
};

export default Index;