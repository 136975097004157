import React from 'react';
import { Grid, Typography } from '@mui/material';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import UpdateIcon from '@mui/icons-material/Update';
import PublicIcon from '@mui/icons-material/Public';
import './home.css';
// import SRS from '../../assets/SRS Logo 2.png';
// import GeethaSeeds from '../../assets/Screenshot 2024-09-27 171103 2.png';
// import Aadhyaa from '../../assets/Group 38.png';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useState } from 'react';
import { globalConfigAction } from "../../store/actions";



const KeyFeatures = () => {
  const dispatch = useDispatch()

  const [logos, setLogos] = useState()


  const { successData: globalConfigData } = useSelector(state => state.globalConfigData) || {};



  useEffect(() => {
    dispatch(globalConfigAction());
  }, [dispatch]);

  useEffect(() => {
    if (globalConfigData) {
      setLogos(globalConfigData.logos.seed.slice(1));
      console.log(globalConfigData.logos)
    }
  }, [globalConfigData]);
  return (
    <Grid container direction="column" sx={{ width: '100%' }}>
      <Grid
        container
        spacing={{ xs: 2, sm: 4 }}
        direction={{ xs: 'column', sm: 'row' }}
        sx={{
          justifyContent: 'space-around',
          alignItems: { xs: 'flex-start', sm: 'center' },
          padding: { xs: '10% 2% 2% 2%', sm: '20px' },
          background: { xs: 'linear-gradient(177.95deg, #FFFFFF 9.32%, rgba(185, 195, 252, 0.41) 77.36%)', sm: 'none' }
        }}
      >
        <Grid item sx={{
          display: 'flex',
          alignItems: 'center',
          color: '#31303b',
          fontSize: { xs: '1rem', sm: '1rem' },
          fontWeight: 500,
          fontFamily: 'Georgia, serif',
          gap: '10px'
        }}>
          <StarBorderIcon sx={{ fontSize: { xs: '1.5rem', sm: '2rem' } }} />
          <span>Spot Threats Before They Happen</span>
        </Grid>
        <Grid item sx={{
          display: 'flex',
          alignItems: 'center',
          color: '#31303b',
          fontSize: { xs: '1rem', sm: '1rem' },
          fontWeight: 500,
          fontFamily: 'Georgia, serif',
          gap: '10px'
        }}>
          <PersonSearchIcon sx={{ fontSize: { xs: '1.5rem', sm: '2rem' } }} />
          <span>Faster Debt Recovery</span>
        </Grid>
        <Grid item sx={{
          display: 'flex',
          alignItems: 'center',
          color: '#31303b',
          fontSize: { xs: '1rem', sm: '1rem' },
          fontWeight: 500,
          fontFamily: 'Georgia, serif',
          gap: '10px'
        }}>
          <UpdateIcon sx={{ fontSize: { xs: '1.5rem', sm: '2rem' } }} />
          <span>Real-time Updates</span>
        </Grid>
        <Grid item sx={{
          display: 'flex',
          alignItems: 'center',
          color: '#31303b',
          fontSize: { xs: '1rem', sm: '1rem' },
          fontWeight: 500,
          fontFamily: 'Georgia, serif',
          gap: '10px'
        }}>
          <PublicIcon sx={{ fontSize: { xs: '1.5rem', sm: '2rem' } }} />
          <span>Trustworthy Network</span>
        </Grid>
      </Grid>


      <Grid item sx={{ textAlign: 'center', padding: '20px' }}>
        <Typography variant="h2" sx={{
          fontFamily: 'Ruwudu',
          fontSize: { xs: '1.5rem', sm: '2rem' },
          color: '#000',
          marginBottom: '20px'
        }}>
          Our Network
        </Typography>
        <Grid container sx={{
          display: 'flex',
          alignItems: 'center',
          minHeight: '120px',
          position: 'relative',
          overflow: 'hidden',
          borderRadius: '30px',
          backgroundColor: '#fff',
          maxWidth: { sm: '100vw', xs: '89vw' },
          padding: '0 20px',
          boxSizing: 'border-box'
        }}>
          <Grid container spacing={4} sx={{
            display: 'flex',
            
            flexWrap: 'nowrap',
            gap: '50px',
            animation: 'scroll 20s linear infinite',
            whiteSpace: 'nowrap',
          
            '@keyframes scroll': {
              '0%': { transform: 'translateX(25%)' },
              '100%': { transform: 'translateX(-100%)' }
            },
            '&:hover': {
              animationPlayState: 'paused'
            },
          }}>
            {logos?.map((logoUrl, index) => (
              <Grid item key={index}>
                <img
                  src={logoUrl}
                  alt={`Logo ${index}`}
                  style={{
                    maxWidth: '150px',
                    maxHeight: '80px',
                    height: 'auto'
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default KeyFeatures;
