import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SearchIcon from "@mui/icons-material/Search";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState, useCallback } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SideBar from "../../components/sidebar";
import Table from "../../components/table";
import { getMyPartnerAction } from "../../store/actions";
import TableData from "../../utils/tableutils";
import Loader from "../../components/loader";
import MoreDetails from "../moredetails/ratemycustomer";
import "../moredetails/moredetails.scss";
import "../side/Side.css";
import "./Dashboard.scss";
import "./modal.scss";

const Dashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [partnersData, setPartnersData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const { loading: loadingMyPartners, successData: myPartnersData } = useSelector((state) => state.getMyPartnerData) || {};

  useEffect(() => {
    const handlePopState = () => navigate('/dashboard');
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [navigate]);

  useEffect(() => {
    dispatch(getMyPartnerAction());
  }, [dispatch]);

  useEffect(() => {
    if (myPartnersData && myPartnersData.length > 0) {
      setPartnersData(myPartnersData);
    }
  }, [myPartnersData]);

  const onSearchChange = useCallback((e) => {
    const value = e.target.value.toLowerCase();
    setSearchValue(value);
    if (value) {
      const filteredData = myPartnersData.filter(item =>
        item.business_partner_assoc.business.name.toLowerCase().includes(value)
      );
      setPartnersData(filteredData);
    } else {
      setPartnersData(myPartnersData);
    }
  }, [myPartnersData]);

  const onClickEditDetails = (rowIndex) => {
    const rowData = partnersData[rowIndex];
    navigate("/editviewrating", {
      state: { partnerData: rowData, isEdit: true },
    });
  };

  const onClickViewDetails = (rowIndex) => {
    const selectedAssocPartner = partnersData[rowIndex].business_partner_assoc;
    navigate("/partnerdetails", {
      state: { selectedAssocPartner },
      replace: false,
    });
  };

  const onUpdateRatingSuccess = () => {
    setShowModal(false);
    dispatch(getMyPartnerAction());
  };

  const columns = React.useMemo(() => TableData.myPartners, []);

  const renderPartnersContent = () => {
    if (loadingMyPartners) {
      return <Loader className="loader" />;
    }
    if (partnersData.length > 0) {
      return (
        <Table
          columns={columns}
          data={partnersData}
          onClickViewDetails={onClickViewDetails}
          onClickEditDetails={onClickEditDetails}
        />
      );
    }
    return (
      <Typography
        variant="h5"
        align="center"
        className="no-partners-message"
      >
        Please link your partner with "Search with GST or PAN"!
      </Typography>
    );
  };

  return (
    <>
      <Box sx={{ display: "flex", minHeight: "90vh" }}>
        <SideBar />
        <Box component="main" sx={{ flexGrow: 1, p: 2 }} mt={8}>
          <div>
            <p className="heading1">Partner Data</p>
            <div className="SerchandTotalpartners_container">
              <div className="searchInputContainer">
                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                  <TextField
                    id="search"
                    value={searchValue}
                    onChange={onSearchChange}
                    label="Search"
                    variant="standard"
                  />
                  <SearchIcon sx={{ color: "#0e3c58", mr: 1, my: 0.5 }} />
                </Box>
              </div>
              <div>
                <p className="customer customerText" style={{backgroundColor:'#2b4257'}}>
                  Total Partners: {partnersData.length}
                </p>
              </div>
            </div>
          </div>
          <Grid xs={12} sm={12} md={12} lg={9} ml={2} mt={2}>
            {renderPartnersContent()}
          </Grid>
        </Box>
      </Box>
      <Modal
        style={{ marginTop: "60px", width: "100%" }}
        show={showModal}
        onHide={() => setShowModal(false)}
        size="lg"
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body className="ratemycustomer">
          <MoreDetails isEdit={true} onUpdateRatingSuccess={onUpdateRatingSuccess} />
          <FontAwesomeIcon
            className="close ratemycustomerclose top-right-icon"
            icon={faTimes}
            onClick={() => setShowModal(false)}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Dashboard;
