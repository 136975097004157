import React from 'react';
import { Box, Grid, Typography, Paper, useTheme, useMediaQuery } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EmailIcon from '@mui/icons-material/Email';
import call from '../../assets/call.png';
import facebook from '../../assets/facebook.png';
import instagram from '../../assets/instagram.png';
import linkedin from '../../assets/linkedin.png';
import youtube from '../../assets/youtube.png';
import clearbiz2 from '../../assets/clearbiz2.png';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { useNavigate } from 'react-router-dom';

const Benefits = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box sx={{ flexGrow: 1, backgroundColor: '#2b4257', color: '#fff', p: 3 }}>
      <Paper
        elevation={15}
        className="banner"
        sx={{
          boxShadow: '0 0px 30px rgba(0, 0, 0, 0.2)',
          borderRadius: '16px',
          textAlign: 'center',
          maxWidth: { xs: '90%', md: '80%' },
          margin: 'auto',
          p: 3,
        }}
      >
        <Typography variant={isSmallScreen ? 'h6' : 'h4'}
          sx={{ color: '#2b4257', fontWeight: 'bold', mb: 2 }}>
          Join us in building a better business ecosystem!
        </Typography>
        <Typography
          variant="h6"
          sx={{
            color: '#2b4257',
            marginBottom: '2%',
            display: { xs: 'none', sm: 'block' }
          }}>
          At ClearBiz, we're committed to helping you connect with partners you can trust.
        </Typography>
        <button className="get-started-button" onClick={() => navigate('/register')}>Get Started</button>
      </Paper>

      <Box sx={{ textAlign: 'center', }}>
        <img
          src={clearbiz2}
          alt="Clearbiz"
          style={{
            width: isSmallScreen ? '70%' : '40%',
            height: 'auto',
            alignSelf: 'center',
            marginBottom: isSmallScreen ? '-15px' : '-25px',
            marginTop: isSmallScreen ? '-25%' : '-10%'
          }}
        />
        <Typography
          variant={isSmallScreen ? 'subtitle2' : 'h5'}
          sx={{
            color: 'white',
            mx: { xs: '5%', md: 'auto' },
            textAlign: 'center',
            marginTop: isSmallScreen ? '-6%' : '-1%'
          }}
        >
          Dhanda karne ka naya tarika
        </Typography>
      </Box>

      <Grid container spacing={2} sx={{ p: 3 }}>
        <Grid item xs={12} md={6} sx={{ textAlign: { xs: 'center', md: 'right' } }}>
          {/* <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
            <img src={call} alt="Call" style={{ width: '24px', height: '24px' }} />
            <Typography variant="h6">8520952000</Typography>
          </Box> */}
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1,marginLeft:'8%' }}>
            <a
              href="https://wa.me/message/MDJUUX4QBYARG1"
              target="_blank"
              rel="noopener noreferrer"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <WhatsAppIcon sx={{ fontSize: 30, color: '#fff', backgroundColor: '#1faf38', borderRadius: '50%' }} />
            </a>
            <Typography variant="h6">8520952000</Typography>
          </Box>

        </Grid>

        <Grid item xs={12} md={6}>
          <Box sx={{ display: 'flex', marginRight:'15%',alignItems: 'center', gap: 1, justifyContent: { xs: 'flex-start', md: 'flex-end' } }}>
            <EmailIcon sx={{ fontSize: 30, color: '#fff' }} />
            <Typography variant="h6">support@clearbiz.ai</Typography>
          </Box>
          <Box sx={{ marginRight:'31.5%',display: 'flex', alignItems: 'center', gap: 1, justifyContent: { xs: 'flex-start', md: 'flex-end' }, mt: 2 }}>
            {[{
              src: instagram,
              link: 'https://www.instagram.com/clearbiz.ai/profilecard/?igsh=bGhzemtlejY5ajg='
            },
            {
              src: facebook,
              link: 'https://www.facebook.com/profile.php?id=61551262877534&mibextid=ZbWKwL'
            },
            {
              src: linkedin,
              link: 'https://www.linkedin.com/in/vignan-nalam-868116291?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app'
            },
            {
              src: youtube,
              link: 'https://youtube.com/@clear-biz?si=61IOpmcN_VKAwISd'
            }].map((icon, index) => (
              <a
                key={index}
                href={icon.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={icon.src} alt="social-icon" style={{ width: '24px', height: '24px' }} />
              </a>
            ))}
          </Box>

        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ padding: '0% 5%' }}>
        <Grid item xs={12} md={6}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, flexDirection: isSmallScreen ? 'row' : 'row' }}>
            <LocationOnIcon sx={{ fontSize: 30, color: '#fff', marginTop: { xs: '-30%', sm: '-7%' }, marginRight: { xs: '-5%', sm: '0%' } }} />
            <Typography variant={isSmallScreen ? 'caption' : 'body1'}
              component="div" sx={{ textAlign: isSmallScreen ? 'left' : 'left' }}>
              Operational Address:<br/>
              4-2-163/C2/4,Gandhi Chowk,<br/>
              Adilabad,Telangana-504001<br />
            </Typography>
          </Box>
        </Grid>
        {!isSmallScreen && (
          <Grid item xs={12} md={6}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2,marginLeft:'50%' }}>
              <LocationOnIcon sx={{ fontSize: 30, color: '#fff', marginTop: '-15%', marginRight: '0%' }} />
              <Typography variant="body1" component="div">
                Registered Address:<br/> 
                Door No.4-1-100, Kumarpet, <br/>
                Adilabad,Telangana 504001
              </Typography>
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default Benefits;
