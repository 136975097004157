import React, { useState } from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Typography,
  Grid,
  TextField,
  Button,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useDispatch, useSelector } from 'react-redux';
import { getDistricts, getStates } from '../../../utils/index';
import Table from '../../../components/table';
import TableData from '../../../utils/tableutils';
import { getUserSearchByLocation ,adminUserRatingDetailsAction} from '../../../store/actions';
import Loader from '../../../components/loader';
import industryTypes from '../../../utils/industrytypes';
import { useNavigate } from 'react-router-dom';

const SearchWithDB = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [selectedState, setSelectedState] = useState('');
  const [selectedDistrict, setSelectedDistrict] = useState('');
  const [selectedCity, setSelectedCity] = useState('');
  const [selectedIndustry, setSelectedIndustry] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [usersData,setUsersData]=useState()

  const statesData = getStates();

  const onChangeDropDown = (event) => {
    const { name, value } = event.target;
    if (name === 'state') {
      setSelectedState(value);
      setSelectedDistrict('');
      setSelectedCity('');
    } else if (name === 'district') {
      setSelectedDistrict(value);
      setSelectedCity('');
    } else if (name === 'industry') {
      setSelectedIndustry(value);
    }
  };

  const onCityChange = (event) => {
    setSelectedCity(event.target.value);
  };

  const districtData = React.useMemo(() => {
    if (selectedState) {
      return getDistricts(selectedState);
    }
    return [];
  }, [selectedState]);

  const { successData, loading } =
    useSelector((state) => state.getUserSearchByLocation) || {};

  const { successData: ratingData }=useSelector((state) => state.getAdminUserRatingDetailsReducer) || {};
  

  const columns = React.useMemo(() => TableData.serachDB, []);



 React.useEffect(() => {
  if (successData) {
    setUsersData(successData); 
    console.log("successData", successData);
  }
}, [successData]);

  const onSearchChange = (e) => {
  const searchTerm = e.target.value.toLowerCase();
  if (searchTerm !== "") {
    const filteredData = successData.filter((item) =>
      item.user.business.name.toLowerCase().includes(searchTerm)
    );
    setUsersData(filteredData);
  } else {
    setUsersData(successData);
  }
  };
  
  const onClickViewDetails = (rowIndex) => {
    const selectedAssocPartner = successData[rowIndex].user.id;
    const selectedAssocPartnerName = successData[rowIndex];
    console.log("selectedAssocPartner", selectedAssocPartnerName);
    
   
    navigate('userRatingDetails',{state: {selectedAssocPartner}, replace: true,}); 
  };

  const onSubmit = () => {
    let apiUrl = `admin/userSearchByLocation/?state=${selectedState}&district=${selectedDistrict}`;
    if (selectedCity) {
      apiUrl += `&city=${selectedCity}`;
    }
    if (selectedIndustry) {
      apiUrl += `&industry=${selectedIndustry}`;
    }
    dispatch(getUserSearchByLocation({ endPoint: apiUrl }));
    
    setSubmitted(true);
    setUsersData(successData)
  };


  return (
    <Box
      height="100%"
      minHeight="74vh"
      boxShadow={2}
      p={2}
      m={2}
      borderRadius={1}
      padding={2}
      display={'flex'}
      flexDirection={'column'}
    >
      <Typography variant="h4">Search With DB</Typography>
      <Box display={'flex'} flexDirection={'column'} gap={1} padding={3}>
       <Grid container spacing={2}>
       <Grid item lg={3} xs={12} sm={6}>
        <FormControl sx={{width:"200px"}} >
          <InputLabel htmlFor="state">State</InputLabel>
          <Select
            value={selectedState}
            name="state"
            id="state"
            onChange={onChangeDropDown}
            label="State"
          >
            {statesData.map((state) => (
              <MenuItem key={state.value} value={state.value}>
                {state.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item lg={3} xs={12} sm={6}>
        <FormControl sx={{width:"200px"}}>
          <InputLabel htmlFor="district">District</InputLabel>
          <Select
            value={selectedDistrict}
            name="district"
            id="district"
            onChange={onChangeDropDown}
            label="District"
            disabled={!selectedState}
          >
            {districtData.map((district) => (
              <MenuItem key={district.value} value={district.value}>
                {district.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item lg={3} xs={12} sm={6}>
        <FormControl sx={{width:"200px"}}>
          <TextField
            value={selectedCity}
            name="city"
            id="city"
            label="City"
            onChange={onCityChange}
            disabled={!selectedState || !selectedDistrict}
          />
        </FormControl>
      </Grid>
      <Grid item lg={3} xs={12} sm={6}>
        <FormControl sx={{width:"200px"}}>
          <InputLabel htmlFor="industry">Industry</InputLabel>
          <Select
            value={selectedIndustry}
            name="industry"
            id="industry"
            onChange={onChangeDropDown}
            label="Industry"
          >
            {industryTypes.map((industry) => (
              <MenuItem key={industry.id} value={industry.id}>
                {industry.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
       </Grid>
        <Box display={'flex'} flexDirection={'column-reverse'} >
          {successData?( <Grid item xs={12} sm={6}>
              <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                <TextField
                  id="search"
                  onChange={onSearchChange}
                  label="Search"
                  variant="standard"
                />
                <SearchIcon sx={{ color: "#0e3c58", mr: 1, my: 0.5 }} />
              </Box>
            </Grid>
):""}
          <Box sx={{ textAlign: 'right', marginTop: '16px' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={onSubmit}
            disabled={!selectedState || !selectedDistrict}
          >
            Submit
          </Button>
        </Box>
       </Box>
      
      </Box>

      {loading ? <Loader className="loader" /> :(<Grid container mt={2}>
        {usersData && usersData.length > 0 ? (
          <Table
            columns={columns}
            data={usersData}
            onClickViewRatingDetails={onClickViewDetails}
          />
        ) : (
           
              <h1 >
            {!submitted
              ? 'Select State and City for results'
              : 'No users found'}
          </h1>
          
        )}</Grid>)}
    </Box>
  );
};

export default SearchWithDB;
