import React from "react";
import './content.scss'

const ContactUs = () => {
  return (
    <>
      <p className='termsHeading'>Contact us</p>
      <hr className='separate4'/>
      <div className='text-color'>
      <p>You may contact us using the information below:</p>
      <p>
        Merchant Legal entity name: CLEARBIZ SOFTWARE SOLUTIONS PRIVATE LIMITED{" "}
        <br />
        Registered Address: Door No.4-1-100 ,Kumarpet, Adilabad{" "}
        <br />
        TELANGANA 504001 <br/> Operational Address: Door No.4-1-100 ,Kumarpet, Adilabad{" "}
        <br />
        TELANGANA 504001 <br /> Telephone No: 8520952000<br />
        E-Mail ID: support@clearbiz.ai
      </p>
      </div>
    </>
  );
};

export default ContactUs;
