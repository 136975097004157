const data = {
    percentages:
        [
            {
                value: 0,
                label: "100%"
            },
            {
                value: 1,
                label: "90%"
            },
            {
                value: 2,
                label: "75%"
            },
            {
                value: 3,
                label: "50%"
            },
            {
                value: 4,
                label: "25%"
            },
            {
                value: 5,
                label: "0%"
            }
        ],
    reachability: [
        { value: 0, label: "Yes" },
        { value: 2, label: "Difficult" },
        { value: 3, label: "No" },
       
    ],
    recoveryprobability: [
        { value: 0, label: "Yes" },
        { value: 1, label: "Yes with multiple attempts" },

        { value: 2, label: "Difficult" },
        { value: 3, label: "No" },
       
    ],
    score: [
        { value: 10, label: "Excellent" },
        { value: 9, label: "Good" },
        { value: 8, label: "Average" },
        { value: 7, label: "Service Delay" },
        { value: 6, label: "Lack of Post-Sale Support" },
        { value: 5, label: "Market Disruption/Dispute/UnderCutting"},
        { value: 4, label: "Payment Delay" },
        { value: 3, label: "Non-Delivery After Payment" },
        { value: 2, label: "Critical Due/Payment Default" },
        { value: 1, label: "Fraudulent Activity" }
    ],
   
    totaloutstanding: [
        { value: 0, label: "0" },
        { value: 1, label: "<2 Lakhs" },
        { value: 2, label: "2-5 Lakh" },
        { value: 3, label: "5-10 Lakh " },
        { value: 4, label: "10-20 Lakh" },
        { value: 5, label: "20-50 Lakh" },
        { value: 6, label: "50L-1 Cr" },
        { value: 7, label: '>1 Cr'}
    ],
   pendingrecievable: [
        { value: 0, label: "0" },
        { value: 1, label: "<2 Lakhs" },
        { value: 2, label: "2-5 Lakh" },
        { value: 3, label: "5-10 Lakh " },
        { value: 4, label: "10-20 Lakh" },
        { value: 5, label: "20-50 Lakh" },
        { value: 6, label: "50L-1 Cr" },
        { value: 7, label: '>1 Cr'}
    ],

    dso: [
        { value: 0, label: "0 Days" },
        { value: 1, label: "30 Days" },
        { value: 2, label: "60 Days " },
        { value: 3, label: "90 Days" },
        { value: 4, label: "180 Days" },
        { value: 5, label: "1 Year" },
        { value: 6, label: "2 Years" },
    ],
    // transactionpaidontime: [

    //     { value: 0, label: "1 - 5" },
    //     { value: 1, label: "6 - 10" },
    //     { value: 2, label: "11 - 15" },
    //     { value: 3, label: "16 - 25" },
    //     { value: 4, label: "25 +" },
       
    // ],
    // GST: [
    //     { value: 0, label: "No"},
    //     { value: 1, label: "Yes"},
    // ],
    //  capital_or_asseets: [
    //     { value: 0, label: "Yes" },
    //     { value: 1, label: "Moderate" },
    //     { value: 2, label: "No" },
       
       
    // ],
    legal: [
        { value: 0, label: "NO" },
        { value: 1, label: "YES" },
       
       
    ],
}
export default data;