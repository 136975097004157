import React from "react";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import './company.scss'
import { useNavigate } from "react-router-dom";
import clearbiz2 from "../../assets/clearbiz2.png"
import clearbizicon from "../../assets/clearbizicon.png";


const CompanyName = () => {
   const navigate = useNavigate();
  return (
      <Grid container spacing={2} sx={{height:'100px'}}> 
     

    <Grid container spacing={2} padding={2} style={{ backgroundColor: '#16548a' }}>
    <Grid item xs={8} md={10} display="flex" justifyContent="center" direction="column" style={{paddingLeft:'0'}}>
     <Grid display='flex' direction='row'>
      <img className="icon" src={clearbizicon} alt="clearbizicon"/>
    <Typography variant="h5" component="p" className='name' sx={{fontWeight:'bold',fontFamily:'open sans',paddingLeft:'15px',paddingTop:'4px'}}>
    CLEARBIZ SOFTWARE SOLUTIONS PRIVATE LIMITED
    </Typography>
    </Grid>
  </Grid>
  <Grid item display="flex" justifyContent="center" alignItems="centre" direction="column">
    <div>
            <button onClick={()=>navigate('/')} type="button" style={{all: "unset",justifyContent:'flex-end'}}>
             <img src={clearbiz2} alt="ClearBiz Logo" className="icon2" />

            </button>

            
    </div>
  </Grid>
 
</Grid>

</Grid>
  );
};

export default CompanyName;
