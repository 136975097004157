import { useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import CssBaseline from "@mui/material/CssBaseline";
import GlobalStyles from "@mui/material/GlobalStyles";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { getSubscriptionAction } from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from 'prop-types';


function SubscriptionCard({ item, index }) {
  const navigate = useNavigate();

 
  return (
    <Card
      sx={{ 
        margin: "10px",
        width: "280px",
        height: "450px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",  
      }}
    >
      <CardHeader
        title={`${item.type}: ${item.name}`}
        titleTypographyProps={{ align: "center" }}
        subheaderTypographyProps={{ align: "center" }}
        sx={{
          backgroundColor: "#a3cef1" ,
          minHeight: "100px", 
        }}
      />
      <CardContent sx={{ flexGrow: 1 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between", // Ensure content is spaced evenly
            height: "100%",  // Occupy full height of the CardContent
          }}
        >
          <>
            <Typography
              component="h2"
              variant="h5"
              sx={{
                textDecoration: "line-through",
                color: "#888888",
              }}
            >
              {`₹${item.price}/year`}
            </Typography>
            <Typography
              component="p"
              variant="subtitle1"
              sx={{
                textAlign: "center",
                fontWeight: "bold",
                color: "#d90429",
              }}
            >
              {`Save ${Math.round(
                ((item.price - item.offer_price) / item.price) * 100
              )}% on the first-year subscription`}
            </Typography>
            <Typography
              component="h2"
              variant="h4"
              sx={{
                color: "text.primary",
              }}
            >
              {`₹${item.offer_price}/year`}
            </Typography>
            <ul style={{ textAlign: "center" }}>
              {item.description.map((descItem, descIndex) => (
                <li key={descItem}>{descItem}</li>
              ))}
            </ul>
          </>
        </Box>
      </CardContent>
      {/* Button placed at the bottom */}
      <Button
        fullWidth
        variant={"contained"}
        sx={{
          backgroundColor: "#213554" ,
          color: "white",
          marginBottom: "15px", // Space between content and button
        }}
        onClick={() => navigate('/register')}
      >
        {item.display_text}
      </Button>
    </Card>
  );
}

const defaultTheme = createTheme();

export default function PricingPolicy() {
  const dispatch = useDispatch();
  const { successData: subscriptionData } = useSelector(
    (state) => state.getSubscriptionData
  ) || {};

  useEffect(() => {
    dispatch(getSubscriptionAction());
  }, [dispatch]);



  return (
    <ThemeProvider theme={defaultTheme}>
      <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: "none" } }} />
      <CssBaseline />

      <div className="SubscriptionCardContainer"  >
        {subscriptionData?.result
          ?.filter((item) => item.is_active)
          .map((item, index) => (
              <SubscriptionCard  key={item.id} item={item} index={index} />
          ))}
      </div>
    </ThemeProvider>
  );
}

SubscriptionCard.propTypes = {
  item: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
};