import PropTypes from "prop-types"
import React from 'react';
import { Box, Button, Card, CardContent, TextField, Typography,Grid } from '@mui/material';

function GstRegistration({handleFormChange, gst, handleChange, error, handleSubmit, handleLogin}) {
  
    return (
        <div>
 <Box sx={{ minHeight: "90vh"}}>
 
  <Grid container justifyContent={'center'} >
  <Grid item xs={10} lg={6} >
  <img src="/images/clearbiz1.png" width={'70%'} alt=""  />
  <Card elevation={20}   >
    <CardContent justifyContent={'center'}>
    
    <Typography variant="h5" >
              Register to ClearBiz
     </Typography>
        <TextField
          fullWidth
          margin="normal"
          label="GSTIN Number"
          name="gst"
          variant="outlined"
          value={gst}
          onChange={handleChange}
          onInput={(e) =>
            (e.target.value = ("" + e.target.value).toUpperCase())
          }
          helperText={error}
          error={!!error}
        />
        <Button variant="contained" sx={{background:'linear-gradient(90deg, #65A3DD 0%, #1584D4 48.6%, #5D8FBD 100%)'}} onClick={() => {handleSubmit()}}>
          Submit
        </Button>
      <Box mt={2}>
        <Typography variant="body2">
          Don't have a GSTIN?
          <Button onClick={()=>{
            handleFormChange();
          }} color="primary">
            Register with PAN
          </Button>
        </Typography>
      </Box>
      <Box mt={2}>
        <Typography variant="body2">
        Already on ClearBiz?
          <Button onClick={()=>{
            handleLogin();
          }} color="primary">
             Login
          </Button>
        </Typography>
      </Box>
    </CardContent>
  </Card>
  </Grid>
  </Grid>
</Box>
        </div>
    );
}

export default GstRegistration;

GstRegistration.propTypes = {
  handleFormChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleLogin:PropTypes.func.isRequired,
  gst: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  error: PropTypes.string.isRequired,
 
 }